import React, { useMemo } from 'react'
import * as PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { withMemo, withWebsiteOpen, withGameClosed } from '../../decorators'
import { useIntl } from '../../intl/intl'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import LangSelector from '../../components/LangSelector'
import HomeTemplate from '../../templates/Home'
import router from '../../router'
import routes from '../../router/routes'
import useLocalStorage from '../../hooks/useLocalStorage'
import useLangSelector from '../../hooks/useLangSelector'
import useConfiguration from '../../hooks/useConfiguration'
import * as appConfiguration from '../../configuration'


const VR = (props) => {
  const t = useIntl()
  const { data, pageContext, location } = props
  const { isUserOnboarded } = useLocalStorage()
  const { configuration } = useConfiguration()
  const { langSelectorProps } = useLangSelector({ links: configuration?.locales ?? [], locale: pageContext?.language ?? '' })

  const vrTemplateProps = useMemo(() => ({
    title: t('balenciaga_fall_21'),
    welcomeText: isUserOnboarded ? null : data?.contentfulOnboarding?.startMessage?.json ?? '',
    layoutProps: {
      seo: {
        lang: pageContext?.language ?? null,
        title: data?.contentfulOnboarding?.seo?.title ?? '',
        description: data?.contentfulOnboarding?.seo?.description?.description ?? '',
        image: appConfiguration.app.url + data?.contentfulOnboarding?.seo?.picture?.localFile?.childImageSharp?.fluid?.src ?? '',
        url: location?.href ?? '',
        robots: 'noindex, follow',
      },
      header: <Header
        isFallHidden
        navigation={
          pageContext?.language !== 'zh' && <LangSelector {...langSelectorProps} />
        }
      />,
    },
    startNavProps: {
      buttons: isUserOnboarded ? [
        {
          text: t('vr_setup'),
          variant: 'bigtransparent',
          gatsbyLink: {
            route: router(routes.onboarding.path),
          },
        },
        {
          text: t('game'),
          variant: 'bigtransparent',
          gatsbyLink: {
            route: router(routes.vipLanding.path),
          },
        },
        {
          text: t('lookbook'),
          gatsbyLink: {
            route: router(routes.vipLooks.path),
          },
          variant: 'bigtransparent',
        },
      ] : [
        {
          text: t('start'),
          variant: 'bigtransparent',
          gatsbyLink: {
            route: router(routes.onboarding.path),
          },
        },
      ],
      skipLinks: isUserOnboarded ? [] : [
        {
          text: t('get_code'),
          route: router(routes.code.path),
        },
      ],
    },
  }), [t, isUserOnboarded, data.contentfulOnboarding.startMessage.json, data.contentfulOnboarding.seo.title, data.contentfulOnboarding.seo.description.description, data.contentfulOnboarding.seo.picture.localFile.childImageSharp.fluid.src, pageContext.language, location.href, langSelectorProps])

  return (
    <Layout {...vrTemplateProps.layoutProps}>
      <HomeTemplate {...vrTemplateProps} />
    </Layout>
  )
}

VR.propTypes = {
  // eslint-disable-next-line
  data: PropTypes.object,
  // eslint-disable-next-line
  pageContext: PropTypes.object,
  // eslint-disable-next-line
  location: PropTypes.object,
}

VR.defaultProps = {
  data: null,
  pageContext: null,
  location: null,
}

export default withGameClosed(withWebsiteOpen(withMemo()(VR)))

export const query = graphql`
   query vr($language: String){
    contentfulOnboarding(node_locale: { eq: $language }) {
      ...balenciagaF21Onboarding
    }
  }
`
