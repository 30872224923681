import { createUseStyles } from 'react-jss'

import { desktop } from '../../theme'


export default createUseStyles({
  container: {
    alignSelf: 'flex-end',
    paddingTop: '2rem',
    paddingBottom: '4.5rem',
    textAlign: 'center',
  },
  wrapper: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    paddingLeft: '2.8rem',
    paddingRight: '2.8rem',
    minHeight: 'calc(var(--vh, 1vh) * 50)',
    fallbacks: {
      minHeight: '50vh',
    },
    '& > *': {
      width: '100%',
      margin: 0,
    },
    '& > *:not(.is-notstaggered)': {
      opacity: 0, // to prevent FOUC
    },
    '& > * + *': {
      marginTop: '4rem',
    },
    '& > $nav': {
      marginTop: 'auto',
    },
    ...desktop({
      '& > $content': {
        marginTop: 'auto',
      },
      '& > $nav': {
        marginTop: '6rem',
      },
      '& > $header + $nav': {
        marginTop: 'auto',
      },
    }),
  },
  header: {},
  logoMain: {
    display: 'flex',
    justifyContent: 'center',
    width: '21.7rem',
    height: '2.5rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& svg': {
      width: '100%',
      height: 'auto',
    },
    ...desktop({
      width: '23rem',
      height: '3rem',
    }),
  },
  logoFall: {
    display: 'flex',
    justifyContent: 'center',
    width: '6.2rem',
    height: '1.4rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    '&:not(:first-child)': {
      marginTop: '1.8rem',
    },
    '& svg': {
      width: '100%',
      height: 'auto',
    },
    ...desktop({
      width: '7.5rem',
      height: '1.8rem',
    }),
  },
  content: {
    fontSize: '1.2rem',
    lineHeight: '1.2',
    '& > *': {
      margin: 0,
    },
    '& > * + *': {
      marginTop: '0.5rem',
    },
    ...desktop({
      '& > * + *': {
        marginTop: '1rem',
      },
    }),
  },
  nav: {},
})
