import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'

import staggerAnimation from '../../utils/staggerAnimation'
import StartNav from '../../components/StartNav'
import AccessibleHiddenText from '../../components/AccessibleHiddenText'
import RichText from '../../components/RichText'
import Icon from '../../components/Icon'
import { iconsKeys } from '../../components/Icon/Icon.assets'
import withMemo from '../../decorators/withMemo'

import useStyles from './styles'


const HomeTemplate = (props) => {
  const classes = useStyles(props)
  const { className, startNavProps, welcomeText, title } = props

  // animations
  const $animatedWrapper = useRef()

  useEffect(() => {
    const timeline = gsap.timeline()

    if ($animatedWrapper.current) {
      const animatedChildren = Array.from($animatedWrapper.current.children).filter((child) => !child.classList.contains('is-notstaggered'))

      staggerAnimation(0, animatedChildren, timeline, 0.5, '+=0.2', 0.5)
      timeline.play()
    }
    // cleanup
    return () => {
      timeline.clear()
    }
  }, [])

  return (
    <main className={cx(className, classes.container)}>
      <div
        ref={$animatedWrapper}
        className={classes.wrapper}
      >
        <h1 className={classes.header}>
          <AccessibleHiddenText text={title} />
          <Icon
            icon={iconsKeys.Logo}
            className={classes.logoMain}
            aria-hidden="true"
          />
          <Icon
            icon={iconsKeys.Fall21}
            className={classes.logoFall}
            aria-hidden="true"
          />
        </h1>
        {welcomeText && (
          <RichText
            className={classes.content}
            json={welcomeText}
          />
        )}
        {startNavProps && (
          <StartNav
            className={classes.nav}
            {...startNavProps}
          />
        )}
      </div>
    </main>
  )
}

export const HomeTemplatePropTypes = {
  className: PropTypes.string,
  startNavProps: PropTypes.shape(),
  // eslint-disable-next-line react/forbid-prop-types
  welcomeText: PropTypes.object,
  title: PropTypes.string,
}

HomeTemplate.propTypes = HomeTemplatePropTypes

HomeTemplate.defaultProps = {
  className: null,
  startNavProps: null,
  welcomeText: null,
  title: null,
}

export default withMemo()(HomeTemplate)
